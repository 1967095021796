// @flow
import React from 'react';
import {
    withStyles
} from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import PageStyles from '../../styles/home.css';
import OverviewImage from '../../images/overview.png';

const styles = {
    ...PageStyles,
    fillContainer: {
        width: '100%',
        height: '100%'
    }
};

function Title ({ textStyle, children }) {
    return (
        <div>
            <div className={styles.spacer60U} />
            <div className={textStyle}>
                {children}
            </div>
            <div className={styles.spacer30U} />
            <Row>
                <Col md={3} />
                <Col md={6}>
                    <div className={styles.sectionUnderLine} />
                </Col>
                <Col md={3} />
            </Row>
            <div className={styles.spacer30U} />
        </div>
    );
};

function Home() {
    return (
        <div>
            <div className={styles.custom_page}>
                <Title textStyle={styles.title}> Direct4Ag Portal</Title>
                <div className={styles.section_001}>
                    <p>
                            The Direct4Ag Data Portal provides easy access to example sensor data this project will
                        provide access to. This site is under construction.
                    </p>
                    <br />
                    <h3>Features: </h3>
                    <ul className={styles.margin1em}>
                        <li className={styles.text}>Explore and visualize available data from a variety of sources</li>
                        <li className={styles.text}>Search for specific data and download</li>
                    </ul>
                </div>
                <div className={styles.section_002}>
                    <Title textStyle={styles.subtitle}>
                        DSFAS: Digital Infrastructure for Research
                        and Extension on Crops and Technology for
                        Agriculture (Direct4Ag)</Title>

                    <img alt="cur" className={styles.image} src={OverviewImage} />

                    <p>
                        The long-term goal of this project is to build a digitally enabled network of researchers, Extension
                        educators, and producers with a flexible and adaptive system that permits rapid data
                        sharing while improving real-time and information-based on-farm decision making
                    </p>
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(Home);
